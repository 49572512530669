/*!
* Bootstrap v4.5.3 (https://getbootstrap.com/)
* Copyright 2011-2020 The Bootstrap Authors
* Copyright 2011-2020 Twitter, Inc.
* Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
*/

@import "bootstrap/functions";
@import "variables"; // note in root _sass for ease of access, bc most bootstrap customizations happen here
@import "bootstrap/mixins";
@import "bootstrap/root";
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "bootstrap/images";
@import "bootstrap/code";
@import "bootstrap/grid";
@import "bootstrap/tables";
// @import "bootstrap/forms"; let woocommerce and wp do the work
@import "bootstrap/buttons";
@import "bootstrap/transitions";
@import "bootstrap/dropdown";
@import "bootstrap/button-group";
@import "bootstrap/input-group";
// @import "bootstrap/custom-forms"; let woocommerce and wp do the work
@import "bootstrap/nav";
@import "bootstrap/navbar";
@import "bootstrap/card";
@import "bootstrap/breadcrumb";
@import "bootstrap/pagination";
// @import "bootstrap/badge"; dont need so save space
// @import "bootstrap/jumbotron"; dont need so save space
@import "bootstrap/alert";
@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/close";
@import "bootstrap/toasts";
@import "bootstrap/modal";
@import "bootstrap/tooltip";
@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "bootstrap/spinners";
@import "bootstrap/utilities";
@import "bootstrap/print";

// @import "kitchen-sink";  examples from bootstrap download

//@import "lightbox"; // lightbox by lokesh github.com/lokesh/lightbox2/

@import "aos"; // /_sass/aos.scss contains animation on scroll styles

@import "woocommerce"; // /_sass/woocommerce.scss all woocommerce customs

@import "main"; // /_sass/main.scss = custom styles per project
