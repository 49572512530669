// note all woo-commerce woocommerce styles here

// note WooCommerce ---- START -------------

// note Cart mini-cart customs --- Start

// note TEMPORARILY DISPLAY NONE SIDE CART - only on Temp cover now

.left_sd_cart .lft_ct_btn .lft_cta {
    border-radius: 4px !important;
}

li.woocommerce-mini-cart-item {
    a img {
        width: 100px;
        height: 100px;
    }
}
//note sidecar icon
.sticky_cart_icon {
    // position: fixed;
    // top: 50%;
    top: 20% !important;

    @include media-breakpoint-up(lg) {
        top: 30% !important;
    }
    // left: 0px;
    // transform: translateY(-50%);
    // background-color: #FFF0E8;
    // width: 55px;
    // min-height: 55px;
    // border-radius: 0px 12px 12px 0px;
    // box-shadow: 0px 0px 10px 0px rgb(0 0 0/15%);
    // font-family: 'Inter', sans-serif;
    // padding: 10px 8px;
    // box-sizing: border-box;
    // cursor: pointer;
    // z-index: 1;
}

//note sidecart slide-out
.left_sd_outer {
    // max-width: 320px;
    // width: 0%;
    // overflow: hidden;
    // height: 80vh;
    // position: fixed;
    // top: 53%;
    top: 50% !important;
    // left: 0px;
    // transform: translateY(-50%);
    // background-color: #fff;
    // border-radius: 0px 20px 20px 0px;
    // box-shadow: 0px 0px 10px 0px rgb(0 0 0/10%);
    // z-index: 2;
    z-index: 9999 !important;
    // transition: .5s ease-in-out;
    transition: 0.25s ease-in-out !important;
}

body .side_cart_menu {
    // font-family: 'Inter', sans-serif;
    font-family: $font-family-sans-serif !important;
    // z-index: 999 !important;
    z-index: 9999 !important;
    // position: fixed;
}

body .left_sd_cart {
    // background-color: rgb(255, 255, 255, 0.9) !important;
    background-color: rgba(255, 255, 255, 1) !important;
}
// note Checkout-  fixes conflict between woocommerce checkout and Bootstrap 4
.woocommerce {
    .col-1,
    .col-2 {
        max-width: none;
    }
}

// on sale sticker

.woocommerce span.onsale {
    // min-height: 3.236em;
    // min-width: 3.236em;
    // padding: 0.202em;
    // font-size: 1em;
    // font-weight: 700;
    // position: absolute;
    // text-align: center;
    // line-height: 3.236;
    // top: -0.5em;
    // left: -0.5em;
    // margin: 0;
    // border-radius: 100%;
    // background-color: #b3af54;
    // color: #fff;
    // font-size: .857em;
    // z-index: 9;
    top: 3.25em !important;
}

// note woo-commerce styles select menus to select tags and categories and the orderby

.product-category-selection-menu,
.tag-selection-menu {
    text-align: right;
}

.product-category-selection-menu select,
.tag-selection-menu select,
.woocommerce select.orderby {
    text-align: left;
    font-family: $font-family-sans-serif;
    font-size: 16px;
    color: $black;
    padding: 0.125rem;
    width: 200px;
    @include media-breakpoint-up(lg) {
        width: 220px;
    }

    option {
        font-size: 17px;
    }
}

.tag-selection-menu select {
    @include media-breakpoint-up(xl) {
        width: 180px;
    }
}

// note results products list page

// note  pagination nav of products

.woocommerce-pagination {
    text-align: right !important;
    margin-right: 2rem !important;
}

.wc_top_pagination {
    .woocommerce-pagination {
        text-align: left !important;
        margin-left: 1rem !important;
    }
}

a.woocommerce-loop-product__link {
    h2.woocommerce-loop-product__title {
        // padding: 0.5em 0;
        // margin: 0;
        // font-size: 1em;
        font-size: 98% !important;
        padding: 0 0 0.5rem 0.75rem !important;
        font-family: $font-family-sans-serif;
    }

    span.price {
        padding: 0 0 0.5rem 0.75rem;
        font-size: 100%;
    }
}

// style Button on products list
.woocommerce ul.products li.product .button {
    // display: inline-block;
    // margin-top: 1em;
    margin-top: 0 !important;
    margin-left: 0.75rem !important;
}

//style product meta info
.product_meta {
    padding-left: 1rem;
}

// style variations table
.woocommerce div.product form.cart .variations {
    // margin-bottom: 1em;
    // border: 0;
    // width: 100%;
    margin-left: 1rem;
}
// add to cart button
.woocommerce div.product form.cart .button {
    margin-left: 1rem;
}

//style li that has product img, title, and price

ul.products li {
    // only reason this is here is on laptop size the top right icons still appear, content should be layer on top, so when content s directly to left of searchbar section, the link ability remains. this bg white at least looks better when these scroll over the search bar search section with icons.
    background-color: $white;
}

//style price text
.woocommerce ul.products li.product .price,
.woocommerce div.product p.price,
.woocommerce div.product span.price {
    // color: #b3af54;
    // display: block;
    // font-weight: 400;
    // margin-bottom: 0.5em;
    // font-size: .857em;
    color: #474101 !important;
    font-weight: 500 !important;
    margin-bottom: 0.5rem !important;
    font-size: 1.125rem !important;
}

ul.products {
    margin: 4.5rem 0 4rem 1rem !important;

    li.product {
        a {
            img {
                // width: 100%;
                // height: auto;
                // display: block;
                // margin: 0 0 1em;
                margin: 0 0 0.5rem 0 !important;
                // box-shadow: none;
                border-radius: 4px;
                -webkit-transition: all ease-in-out 0.125s;
                -moz-transition: all ease-in-out 0.125s;
                -o-transition: all ease-in-out 0.125s;
                transition: all ease-in-out 0.125s;
                filter: brightness(98%);
                -webkit-filter: brightness(98%);
                // filter: contrast(97%);
                // -webkit-filter: contrast(97%);
                &:hover {
                    filter: brightness(105%);
                    -webkit-filter: brightness(105%);
                    // filter: contrast(102%);
                    // -webkit-filter: contrast(102%);
                }
            }
        }
    }
}

// add to cart button add-to-cart
li.product a.button {
    font-size: 80%;
}

// note woocommrce single-product single product

//hide single nav on product single pages, but still show on single posts.
body.single-product {
    .post-navigation {
        display: none;
    }

    .woocommerce div.product p.stock {
        color: $jewelbox_blue_b1;
    }

    // .woocommerce div.product form.cart {
    //     margin-left: 1rem;
    // }
}

.single-product {
    .entry-title {
        margin-bottom: 2rem;
    }

    .woocommerce div.product div.summary {
        padding: 1rem;
    }

    .product_meta .sku_wrapper {
        display: none;
    }

    //quantity
    .woocommerce .quantity .qty {
        width: 4.5rem;
        /* text-align: center; */
        margin-left: 1rem;
    }
    div.quantity {
        // float: left;
        // margin: 0 4px 0 1rem;
        // margin: 0.25rem 0  0 0  !important;
    }
    //category links
    //tag links
    .posted_in,
    .tagged_as {
        margin-top: 1rem;
        clear: both;
        display: block;
    }
    //category links
    .posted_in {
        margin-top: 3rem;
    }
}

// note start User Account stuff

// note user acct navigation
.woocommerce-MyAccount-navigation {
    ul {
        list-style-type: none;
    }

    li.woocommerce-MyAccount-navigation-link.is-active:before {
        content: "\2726";
    }
}

.woocommerce-MyAccount-content {
    address {
        margin-left: 1.125rem;
    }
}

// note wishlist plug-in

.wlfwc-add-to-wishlist {
    // position: absolute;
    // right: 10px;
    // right: 10px;
    right: 0 !important;
    left: 10px !important;
    // top: 10px;
    top: 10px !important;
    font-size: 16px !important;
    // height: 24px;
    height: 16px !important;
    // width: 24px;
    width: 16px !important;
    @include media-breakpoint-up(lg) {
        // right: 10px;
        right: 0 !important;
        left: 8px !important;
        // top: 10px;
        top: 8px !important;
        // display: block;
        // font-size: 24px;
        font-size: 12px !important;
        // height: 24px;
        height: 12px !important;
        // width: 24px;
        width: 12px !important;
    }
    // line-height: 1;
    // color: #ccc;
    // padding: 8px;
    // background: #fff;
    // box-sizing: initial !important;
    // border-radius: 50%;
    // box-shadow: 0 0 8px rgba(0,0,0,0.25);
    // z-index: 101;
    opacity: 0.5;
    filter: alpha(opacity=70);
    &:hover {
        opacity: 0.8;
        filter: alpha(opacity=80);
    }
}

.wlfwc-add-to-wishlist.active {
    opacity: 0.9;
    filter: alpha(opacity=90);
}

//note check if logged in to allow wishlist and show Account Page welcome message

.wlfwc-add-to-mywishlist,
.wlfwc-add-to-wishlist {
    display: none !important;
}
body.logged-in {
    .wlfwc-add-to-mywishlist,
    .wlfwc-add-to-wishlist {
        display: block !important;
    }
    .hide_logged_in {
        display: none !important;
    }
}

// note wishlist notification on large is way off to right - fix
// color is in plug-in settings via wp-admin plugins setttings

.wl_notify {
    // position: absolute;
    // top: 10px;
    // right: 55px;
    // width: auto;
    // line-height: 1.5;
    // padding: 15px 10px;
    // box-sizing: border-box;
    // background: rgba(255, 222, 255, 0, 0.85);
    // text-align: center;
    // z-index: 2;
    border-radius: 3px !important;
    padding: 16px 10px !important;
    background: rgba(255, 255, 255, 0.9) !important;
    @include media-breakpoint-up(lg) {
        right: 0 !important;
        left: 15px !important;
        width: 300px !important;
    }
}
