// note custom styles go here

// note Global **********************

//bootstrap media queries

// @include media-breakpoint-up(xs) {}

// @include media-breakpoint-up(sm) {}

// @include media-breakpoint-up(md) {}

// @include media-breakpoint-up(lg) {}

// @include media-breakpoint-up(xl) {}

// //bootstrap media queries
// @include media-breakpoint-up(xs) {
// }
// @include media-breakpoint-up(sm) {
// }
// @include media-breakpoint-up(md) {
// }
// @include media-breakpoint-up(lg) {
// }
// @include media-breakpoint-up(xl) {
// }

main,
p {
    // padding: 1rem 1.5rem 2rem 1.5rem;
    font-family: $font-family-sans-serif;
}

h1,
h2,
h3,
h4 {
    font-family: $font-family-serif;
    color: $gray-800;
}

h1,
h2 {
    margin-bottom: 1.5rem;
}

h3,
h4 {
    margin-bottom: 0.75rem;
}

h1,
h2,
h3,
h4,
p {
    padding-right: 1rem;
    padding-left: 1rem;
}

p {
    color: $black;
}

.placeholder {
    border-radius: 2px;
    // border: 1px solid $jewelbox_blue_b5;
    border: 1px solid transparent;
    min-height: 230px;
    padding: 0.75rem;
}

// note wordpress styles **************************** Start *******************

//wp-admin column posted by

/* CSS to truncate 'Posted By' column */
// th.column-posted_by {
//     max-width: 26px; /* Adjust the maximum width as needed */
//     overflow: hidden;
//     text-overflow: ellipsis;
//     white-space: nowrap;
// }

// see woocommerce.scss

main {
    ul {
        overflow: hidden;
        li {
            margin-left: 1rem;
            margin-bottom: 0.5rem;
        }
    }

    // note wp post navigation below single posts in main
    // note wp posts Older and Newer on search results with many results

    .post-navigation,
    .posts-navigation {
        .nav-links {
            padding: 1rem 1rem 1.5rem 1rem;
            font-size: 18px;
        }
        .nav-subtitle {
            font-weight: bold;
            color: $jewelbox_blue_b3;
        }
        .nav-title {
            color: $jewelbox_blue_b1;
        }

        .nav-next {
            text-align: right;
            margin-top: 2rem;
            @include media-breakpoint-up(lg) {
                margin-top: -1.5rem;
            }
        }

        .nav-previous::before {
            content: "< ";
        }

        .nav-next::after {
            content: "> ";
        }
    }

    // note wp Single single.php styles

    // note image in content with caption is a figure

    figure {
        max-width: inherit !important;
        img {
            border-radius: 4px;
            margin-left: 0.125rem;
            margin-right: 0.125rem;
        }
        a img {
            -webkit-transition: all ease-out 0.125s;
            -moz-transition: all ease-out 0.125s;
            -o-transition: all ease-out 0.125s;
            transition: all ease-out 0.125s;
            filter: brightness(98%);
            -webkit-filter: brightness(98%);
            // border: 2px transparent solid;
            &:hover {
                filter: brightness(104%);
                -webkit-filter: brightness(104%);
                // border: 2px $dreambays_gold solid;
            }
        }
        .wp-caption-text {
            padding: 0.5rem 0.25rem 0.25rem 1.25rem;
            font-size: 80%;
        }
    }
    /// note wp image aligns
    img.alignnone,
    figure.alignnone {
        margin: 0 0 1rem 0;
    }
    img.alignleft,
    figure.alignleft {
        clear: both;
        display: block;
        float: none;
        margin: 0 0 1rem 0;

        @include media-breakpoint-up(lg) {
            display: inline;
            float: left;
            margin: 0.25rem 2rem 1rem 0;
        }
    }

    img.alignright,
    figure.alignright {
        clear: both;
        display: block;
        float: none;
        margin: 0 0 1rem 0;

        @include media-breakpoint-up(lg) {
            display: inline;
            float: right;
            margin: 0.25rem 0 1rem 2rem;
        }
    }
    img.aligncenter,
    figure.aligncenter {
        clear: both;
        display: block;
        margin: 0.75rem auto;
    }

    /* note wp images inside main */
    img.alignleft,
    img.alignright,
    img.alignnone {
        border-radius: 4px;
        // -webkit-box-shadow: 0 8px 6px -6px #666;
        // -moz-box-shadow: 0 8px 6px -6px #666;
        // box-shadow: 0 8px 6px -6px #666;
    }
} // note close main

.post-thumbnail img,
a.post-thumbnail img {
    max-width: 100% !important;
    height: auto;
    border-radius: 2px;
}

/* note wp gallery styles in entry-content  */

.entry-content {
    /* .entry-content wrap - - START -- */

    .gallery {
        // gallery wrap START
        display: inline-flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 auto;
        width: 100%;
        justify-content: center;
        @include media-breakpoint-up(md) {
            justify-content: left;
            padding-left: 1rem;
        }

        .gallery-icon img {
            -webkit-transition: all ease-out 0.25s;
            -moz-transition: all ease-out 0.25s;
            -o-transition: all ease-out 0.25s;
            transition: all ease-out 0.25s;
            filter: brightness(97%);
            -webkit-filter: brightness(97%);
            // border: 2px transparent solid;
            &:hover {
                filter: brightness(104%);
                -webkit-filter: brightness(104%);
                // border: 2px $dreambays_gold solid;
            }
        }

        .gallery-item {
            padding: 0;
            margin: 1rem 0.25rem 2rem 0;

            @include media-breakpoint-up(md) {
                margin: 0.5rem 0.125rem 2rem 0;
            }

            @include media-breakpoint-up(lg) {
                margin: 1rem 0.25rem 2rem 0;
            }

            @include media-breakpoint-up(xl) {
                margin: 1rem 0.75rem 2rem 0;
            }

            img {
                // border: 1px lightblue solid;
                // margin: 0 8px 8px 0;
                // width: auto !important;
                // float: none !important;
                border-radius: 5px;
                -webkit-box-shadow: 0 8px 6px -6px #666;
                -moz-box-shadow: 0 8px 6px -6px #666;
                box-shadow: 0 8px 6px -6px #666;
                -webkit-filter: brightness(98%);
                -webkit-transition: all ease-out 0.125s;
                -moz-transition: all ease-out 0.125s;
                -o-transition: all ease-out 0.125s;
                transition: all ease-out 0.125s;
            }

            img.size-thumbnail {
                width: 100%;
                height: 100%;
                margin: 0 auto;
                border-radius: 3px;

                @include media-breakpoint-up(md) {
                    width: 170px;
                    height: 170px;
                    margin: 0;
                }

                @include media-breakpoint-up(lg) {
                    width: 200px;
                    height: 200px;
                    margin: 0;
                }

                @include media-breakpoint-up(xl) {
                    width: 342px;
                    height: 342px;
                    margin: 0;
                }
            }

            img.size-medium {
                width: 100%;
                height: auto;

                @include media-breakpoint-up(md) {
                    width: 220px;
                    height: auto;
                }

                @include media-breakpoint-up(lg) {
                    width: 410px;
                    height: auto;
                }

                @include media-breakpoint-up(xl) {
                    width: 600px;
                    height: auto;
                }
            }

            img:hover {
                -webkit-filter: brightness(104%);
            }
        }

        /* wp caption styles */
        .gallery-caption {
            // font-size: 78%;
            // max-width: 180px;
            // padding: 0 6px 6px 6px;
            // margin: 0 auto;
            display: none;
            // hide captions up front, but they do still show during lightbox slides
        }
    } // gallery wrap END

    // note wp gallery item sizes when columns set to 6
    .gallery-columns-6 {
        .gallery-item {
            img.size-thumbnail {
                width: 64px;
                height: 64px;
                // margin: 0 auto;
                border-radius: 3px;

                @include media-breakpoint-up(md) {
                    width: 130px;
                    height: 130px;
                }

                @include media-breakpoint-up(lg) {
                    width: 142px;
                    height: 142px;
                }

                @include media-breakpoint-up(xl) {
                    width: 180px;
                    height: 180px;
                }
            }
        }
    }

    // note wp gallery item sizes when columns set to 4
    .gallery-columns-4 {
        .gallery-item {
            margin: 1rem 0.25rem 2rem 0;

            @include media-breakpoint-up(md) {
                margin: 1rem 0.5rem 2rem 0;
            }

            @include media-breakpoint-up(lg) {
                margin: 1rem 0.75rem 2rem 0;
            }

            @include media-breakpoint-up(xl) {
                margin: 1rem 0.75rem 2rem 0;
            }
            img.size-thumbnail {
                // let small be columns of 2 instead for bigger images
                width: 170px;
                height: 170px;
                margin: 0 auto;
                border-radius: 3px;

                @include media-breakpoint-up(md) {
                    width: 170px;
                    height: 170px;
                    margin: 0;
                }

                @include media-breakpoint-up(lg) {
                    width: 232px;
                    height: 232px;
                    margin: 0;
                }

                @include media-breakpoint-up(xl) {
                    width: 290px;
                    height: 290px;
                    margin: 0;
                }
            }
        }
    }
}
/* .entry-content wrap -- END -- */

//note gallery pop out styles

// gallery pop-up caption
#slb_viewer_wrap .slb_theme_slb_default .slb_data_title,
#slb_viewer_wrap .slb_theme_slb_default .slb_group_status {
    // font-family: 'Yanone Kaffeesatz', arial, sans-serif;
    font-family: $font-family-sans-serif !important;
    // font-size: 23px;
    font-size: 17px !important;
    margin-top: 0.75rem;
    margin-left: 0.75rem;
}

// note wp gallery on home page only columns of 4 or 5 BEST
// note include the home page test so that looks good too
body.page-template-page-home,
body.page-template-page-testing-new-home-page {
    .gallery {
        justify-content: center;
        @include media-breakpoint-up(md) {
            justify-content: center;
        }

        .gallery-item {
            // note wp gallery on home page only thumbnail  size
            img.size-thumbnail {
                // width: 153px;
                // height: 153px;
                // bigger so one column on small
                width: 100%;
                height: auto;
                margin: 0 auto;
                border-radius: 3px;

                @include media-breakpoint-up(md) {
                    // width: 195px;
                    // height: 195px;
                    width: 206px;
                    height: 206px;
                    margin: 0;
                }

                @include media-breakpoint-up(lg) {
                    // width: 236px;
                    // height: 236px;
                    width: 275px;
                    height: 275px;
                    margin: 0;
                }

                @include media-breakpoint-up(xl) {
                    // width: 350px;
                    // height: 350px;
                    width: 316px;
                    height: 316px;
                    margin: 0;
                }
            }
            // note wp gallery on home page only medium size
            img.size-medium {
                width: 100%;
                height: auto;

                @include media-breakpoint-up(md) {
                    width: 360px;
                    height: auto;
                }

                @include media-breakpoint-up(lg) {
                    width: 470px;
                    height: auto;
                }

                @include media-breakpoint-up(xl) {
                    width: 720px;
                    height: 480px;
                }
            }
        }
    }
}

// note wordpress hilite search terrm
mark.hilite {
    // padding: ;
    padding: 0.125rem;
    // background-color: #fcf8e3;
    background-color: #f8f0c8;
}

//note wp select drop downs for sorting searching through products

// note woo-commerce styles in woocommerce.scss

// note wordpress styles ****************** ********* End  ****** ************************************ *****************

// note Header **********************

// header icons
.searchbar-section {
    i.fas,
    i.fa,
    i.far {
        color: $jewelbox_blue_b1;
        -webkit-transition: all ease-in-out 0.125s;
        -moz-transition: all ease-in-out 0.125s;
        -o-transition: all ease-in-out 0.125s;
        transition: all ease-in-out 0.125s;

        &:hover {
            color: $dreambays_gold;
        }
    }
}

// header icons on small header-icons
.header_icons_small {
    i.fas,
    i.fa,
    i.far {
        color: $jewelbox_blue;
        font-size: 112%;
        margin-left: 4px;
        -webkit-transition: all ease-in-out 0.125s;
        -moz-transition: all ease-in-out 0.125s;
        -o-transition: all ease-in-out 0.125s;
        transition: all ease-in-out 0.125s;
        filter: brightness(103%);
        -webkit-filter: brightness(103%);
        // filter: contrast(97%);
        // -webkit-filter: contrast(97%);
        &:hover {
            filter: brightness(90%);
            -webkit-filter: brightness(90%);
            // filter: contrast(102%);
            // -webkit-filter: contrast(102%);
        }
    }
}

//note nav navbar
// also see _variables.scss

.navbar-nav {
    font-family: $font-family-sans-serif;
    a.nav-link {
        color: #000;
    }
}

img.banner {
    margin-top: 3rem;

    @include media-breakpoint-up(lg) {
        margin-top: 0;
    }
}

.navbar.fixed-top {
    //navbar when scrolled to top
    transition: background-color 100ms linear;
    background-color: rgba($white, 1);

    //note show shadow on small only on .navbar-collapse.show
    .show {
        // box-shadow: 7px 8px 7px $gray-400;
        box-shadow: 3px 3px 3px $gray-400;
    }
    @include media-breakpoint-up(md) {
        box-shadow: none;
    }
    @include media-breakpoint-up(xl) {
        background-color: rgba($white, 0);
    }
}

// no shadow in sub drop down menus on small and medium
.navbar.fixed-top ul.show.dropdown-menu,
.navbar.fixed-top li.show.dropdown.menu-item-has-children {
    box-shadow: none;
    // border-bottom: 1px solid $gray-300;
}

// note logo in navbar
img.header_logo {
    -webkit-transition: all ease-in-out 1.125s;
    -moz-transition: all ease-in-out 1.125s;
    -o-transition: all ease-in-out 1.125s;
    transition: all ease-in-out 1.125s;
    width: 110px;

    @include media-breakpoint-up(lg) {
        width: 125px;
    }

    @include media-breakpoint-up(lg) {
        width: 180px;
    }
}

.navbar.fixed-top.scrolled {
    //navbar when scrolling down

    img.header_logo {
        // max-width: 120px;
        width: 110px;
        @include media-breakpoint-up(lg) {
            width: 125px;
        }
    }

    background-color: rgba($white, 1);
    box-shadow: 0 -2px 7px #333;

    @include media-breakpoint-up(lg) {
        background-color: rgba($white, 1);
    }

    transition: background-color 200ms linear;
    // note logo in navbar
    .navbar-brand {
        display: inline-block !important;
    }
}

a.navbar-brand {
    font-size: 80%;
}

.header_info {
    color: #fff;
}

// note links in navbar
.nav-item {
    text-transform: uppercase;
    // font-size: 94%;
    a.nav-link {
        // color: $bsp-blue;
        -webkit-transition: all ease-in-out 0.125s;
        -moz-transition: all ease-in-out 0.125s;
        -o-transition: all ease-in-out 0.125s;
        transition: all ease-in-out 0.125s;
        // color: see _variables navbar $navbar-light-color:
        letter-spacing: 0;
        margin-left: 0.75rem;
        font-size: 94%;
        font-weight: 500;
        @include media-breakpoint-up(lg) {
            font-size: 105%;
            letter-spacing: 1px;
            margin-left: 1.25rem;
            &:hover {
                // text-decoration: underline;
                text-decoration: overline;
                text-decoration-color: $jewelbox_blue_b5;
                // color: $jewelbox_blue_b1;
            }
        }
    }
}

li.nav-item.current-menu-item,
li.nav-item.current_page_item,
li.current-menu-item {
    a.nav-link {
        -webkit-transition: all ease-in-out 0.25s;
        -moz-transition: all ease-in-out 0.25s;
        -o-transition: all ease-in-out 0.25s;
        transition: all ease-in-out 0.25s;
        color: $jewelbox_blue;
        font-weight: bolder;
        text-decoration: overline;
        text-decoration-color: $jewelbox_blue_b5;
        &:hover {
            text-decoration: overline;
            text-decoration-color: $jewelbox_blue_b5;
            @include media-breakpoint-up(lg) {
                color: $jewelbox_blue_b2;
            }
        }
    }
}

.current-menu-item > a,
.current-menu-parent > a {
    text-decoration: overline;
    text-decoration-color: $jewelbox_blue_b5;
    .current-menu-item ul li > a {
        text-decoration: none;
    }
    li.dropdown.nav-item.current-menu-item {
        text-decoration: overline;
        text-decoration-color: $jewelbox_blue_b5;
    }

    &:hover {
        text-decoration: overline;
        text-decoration-color: $jewelbox_blue_b5;

        @include media-breakpoint-up(lg) {
            color: $jewelbox_blue_b2;
        }
    }
}

// note dropdown menu on large and small
ul.dropdown-menu {
    // position: absolute;
    // top: 100%;
    // left: 0;
    // z-index: 1000;
    // display: none;
    // float: left;
    // min-width: 10rem;
    min-width: 14rem;
    // padding: 0.5rem 0;
    // margin: 0.125rem 0 0;
    // font-size: 1.125rem;
    font-size: 0.8rem;
    // color: #212529;
    color: #333;
    // text-align: left;
    text-align: right;
    // list-style: none;
    // background-color: #fff;
    // background-clip: padding-box;
    // border: 1px solid rgba(0,0,0,0.15);
    // border-radius: 0.25rem;
    border: 0px;
    margin: 0.125rem 0 0.125rem 1.5rem;
    @include media-breakpoint-up(lg) {
        border: 1px solid rgba(0, 0, 0, 0.15);
        margin: 0.125rem 0 0;
    }
    li.dropdown.menu-item {
        // font-size: 105%;

        // @include media-breakpoint-up(lg) {
        //     font-size: 98%;
        // }
    }
    //nav-link in dropdown
    a.nav-link {
        margin-left: 0.5rem;
        font-size: 120%;
        @include media-breakpoint-up(lg) {
            font-size: 110%;
        }
    }
}

//note sub-header Search and Cart

/* note Header * * ****************** ********* END */

/* Note Search Bar Container Styles
-------------------------------------------------------------- */
/* Custom CSS for the search bar */
/* Custom CSS for the search bar container */

// search icon magnifying-glass

.btn {
    i.fa-search {
        color: $jewelbox_blue_b1;
        -webkit-transition: all ease-in-out 0.125s;
        -moz-transition: all ease-in-out 0.125s;
        -o-transition: all ease-in-out 0.125s;
        transition: all ease-in-out 0.125s;
        filter: brightness(102%);
        -webkit-filter: brightness(102%);
        // filter: contrast(97%);
        // -webkit-filter: contrast(97%);
        &:hover {
            filter: brightness(96%);
            -webkit-filter: brightness(96%);
            // filter: contrast(102%);
            // -webkit-filter: contrast(102%);
        }
    }
}

#searchbar_modal .modal-content {
    background-color: $jewelbox_blue;
    i.fas {
        color: silver;
    }
}

// note search-bar container
/* Custom CSS to fix both elements */
#search-bar-container {
    position: fixed;
    top: 65px; /* Adjust as needed to make it appear below the navigation bar */
    right: 0;
    left: 0;
    z-index: 0; /* this works so body content doesnt get tucked behind when directly across */
}

/* Optional: Adjust the search input and button styles */
.searchbar {
    .searchbar-section {
        @include media-breakpoint-up(lg) {
            z-index: 1080; /* Ensure the search bar appears above other content */
        }
    }

    // note search-bar container icons on xl up
    .fa,
    .far,
    .fas {
        font-size: 23px;
        margin-top: 0.25rem;
        margin-left: 0.25rem;
    }
    input.search-text {
        width: 185px; /* Adjust the width as needed */
        border: 1px $dreambays-silver-light solid;
        border-radius: 2px;
        padding: 9px;
        font-size: 67%;
        color: $jewelbox_blue;
        font-weight: bold;
        letter-spacing: 1px;
    }

    input::placeholder {
        color: $jewelbox_blue_b1;
    }

    /* Optional: Adjust the search button color */
    button,
    button[type="submit"] {
        background-color: transparent;
        color: $jewelbox_blue_b1;
        border-color: transparent;
    }
}

/*---- End Search Styles ----*/

// note Body **********************

main {
    min-height: 450px;
    padding-bottom: 2rem;

    p {
        line-height: 1.75;
        a {
            -webkit-transition: all ease-in-out 0.25s;
            -moz-transition: all ease-in-out 0.25s;
            -o-transition: all ease-in-out 0.25s;
            transition: all ease-in-out 0.25s;
            color: $jewelbox_blue_b1;
            text-decoration: underline;
            text-decoration-color: $jewelbox_blue_b6;
            &:hover {
                color: $jewelbox_blue;
                text-decoration: underline;
                text-decoration-color: $jewelbox_blue_b5;
            }
        }

        a.btn {
            text-decoration: none;
            &:hover {
                text-decoration: none;
            }
        }
    }
} // end main

// note Article, Cards, fields, etc. **********************

// note Cards

.card {
    margin-bottom: 2.75rem;

    .card-title {
        text-align: center;
        padding-left: 0.62rem;
        padding-right: 0.62rem;
        // font-size: 1.25rem;
        // font-family: $font-family-sans-serif;
        font-family: $font-family-serif;
        font-size: 1.3rem;
        // font-weight: bold;
        margin-bottom: 0.65rem;
        margin-top: 0.75rem;
    }

    a img {
        -webkit-transition: all ease-in-out 0.125s;
        -moz-transition: all ease-in-out 0.125s;
        -o-transition: all ease-in-out 0.125s;
        transition: all ease-in-out 0.125s;
        filter: brightness(98%);
        -webkit-filter: brightness(98%);
        // filter: contrast(97%);
        // -webkit-filter: contrast(97%);
        &:hover {
            filter: brightness(106%);
            -webkit-filter: brightness(106%);
            // filter: contrast(102%);
            // -webkit-filter: contrast(102%);
        }
    }
    .card-body {
        padding: 0.25rem;
        min-height: 93px;
        p {
            line-height: 1.5;
        }
    }
    .card-text {
        font-size: 91%;
    }
}
//note Home

// note carousel styles
.home_carousel {
    position: relative;
    z-index: -1 !important; // this makes sure search wish list and shop icon dont hide under carousel
}

//note used to be an attempt for home parallax effect on carousel START

.carousel-caption {
    // this is now the top banner notice see header_wp_top_notice_banner
    // position: absolute;
    // right: 15%;
    // bottom: 20px;
    // left: 15%;
    // z-index: 10;
    // padding-top: 20px;
    // padding-bottom: 20px;
    // color: #fff;
    // text-align: center;
    // position: absolute;
    // top: 5rem;
    // right: 0;
    // bottom: 0;
    // left: 0;
    // z-index: 10;
    // padding-top: 10px;
    // padding-bottom: 10px;
    // color: $white;
    // text-shadow: 1px 2px 2px $jewelbox_blue_b2;
    // text-align: center;

    // @include media-breakpoint-up(lg) {
    //     top: 11rem;
    // }
}

//note top banner NOTICE banner //
.top_banner_notice {
    // margin-top: 4rem;
    -webkit-transition: all ease-in-out 0.25s;
    -moz-transition: all ease-in-out 0.25s;
    -o-transition: all ease-in-out 0.25s;
    transition: all ease-in-out 0.25s;
    font-family: $font-family-serif;
    background-color: $jewelbox_blue_b6;
    width: 100%;
    // color: $white;

    // &:hover {
    //     background-color: $jewelbox_blue_b5;
    // }

    a {
        font-family: $font-family-serif;
        -webkit-transition: all ease-in-out 0.125s;
        -moz-transition: all ease-in-out 0.125s;
        -o-transition: all ease-in-out 0.125s;
        transition: all ease-in-out 0.15s;
        // color: $white;
        color: #ae1eaa;
        text-decoration: none;
        &:hover {
            // color: $white;
            // text-shadow: 1px 1px 1px $jewelbox_blue;
        }

        h2 {
            font-family: $font-family-serif;
            font-size: 110%;
            // color: #ae1eaa;
            color: #9d0f99;
            letter-spacing: 1px;
            // text-shadow: 1px 2px 2px $jewelbox_blue;
            @include media-breakpoint-up(lg) {
                font-size: 260%;
                letter-spacing: 4px;
            }

            &:hover {
                color: #ad28a9;
            }
        }
    }
}

//note home welcome message

h1.welcome_message {
    font-size: 280%;
}

// note Search Results wordpress search-results search_results page -- START
body.search-results {
    .post-thumbnail img,
    a.post-thumbnail img {
        max-width: 120px !important;

        @include media-breakpoint-up(lg) {
            max-width: 160px !important;
        }

        @include media-breakpoint-up(xl) {
            max-width: 170px !important;
        }
        margin: 0 0.25rem 1.25rem 1rem;
        border-radius: 7px;
        // box-shadow: 0 1px 3px #333;
        -webkit-transition: all ease-out 0.125s;
        -moz-transition: all ease-out 0.125s;
        -o-transition: all ease-out 0.125s;
        transition: all ease-out 0.125s;
        // opacity: 0.9;
        // filter: alpha(opacity=90);
        // filter: grayscale(20%);
        filter: brightness(98%);
        -webkit-filter: brightness(98%);
        border: 2px transparent solid;
        &:hover {
            // opacity: 1;
            // filter: alpha(opacity=100);
            // filter: grayscale(0%);
            filter: brightness(104%);
            -webkit-filter: brightness(104%);
            border: 2px $dreambays_gold solid;
            // box-shadow: 0 1px 3px #333;
        }
    }

    .entry-summary {
        a {
            -webkit-transition: all ease-out 0.125s;
            -moz-transition: all ease-out 0.125s;
            -o-transition: all ease-out 0.125s;
            transition: all ease-out 0.125s;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
                filter: brightness(95%);
                -webkit-filter: brightness(95%);
            }
        }
    }
}
// wordpress Search Results search-results search_results page -- END

// note Footer **********************
.footer_wrapper {
    // background-color: $jewelbox_blue_dk;
    background-color: #15294f;
    // background-image: url("https://ithacajewelbox.com/wp-content/themes/jewelbox2023/assets/img/bg-60-lines.png");
    background-image: url("https://ithacajewelbox.com/wp-content/themes/jewelbox2023/assets/img/bg-white-diamond-dark.png");
}

// mailchimp modal linked from Footer
#mailchimpModal {
    h1,
    h2,
    h3,
    h4,
    h5,
    p,
    label {
        color: $white;
    }
    .modal-header {
        border-bottom: none;
    }
    .modal-content {
        background-color: $jewelbox_blue;

        .subscribe_title {
            color: $white;
            font-size: 112%;
            padding-top: 1rem;
        }
        i.fas {
            color: silver;
        }
    }
}

footer {
    padding-top: 1.5rem;
    padding-bottom: 4rem;
    color: $white;
    font-size: 94%;

    h1,
    h2,
    h3,
    h4,
    p {
        padding-right: 1rem;
        padding-left: 1rem;
        color: $white;
        // font-size: 94%;
        a {
            text-decoration: none;
            &:hover {
                text-decoration: none;
                color: $dreambays_gold_light;
            }
        }
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: $font-family-serif;
    }

    // note footer social media icons
    .footer_icons {
        -webkit-transition: all ease-out 0.125s;
        -moz-transition: all ease-out 0.125s;
        -o-transition: all ease-out 0.125s;
        transition: all ease-out 0.125s;
        font-size: 178%;

        a {
            color: $white;
            margin: 2rem 1rem 2rem 1rem;
            &:hover {
                text-decoration: none;
                color: $dreambays_gold_light;
            }
        }
    }
    .credits_last_line {
        text-transform: none;
        font-size: 80%;
        margin-top: 0.25rem;
    }

    a {
        color: $white;

        &:hover {
            color: $jewelbox_blue_b6;
        }
    }

    p {
        margin-bottom: 0.25rem;
    }
    // note mailchimp embed in footer mc_embed subscribe
    #mc_embed_signup {
        // background: #fff;
        // clear: left;
        // font:
        //     14px Helvetica,
        //     Arial,
        //     sans-serif;
        // width: 320px;
        color: $black;
        .indicates-required {
            display: none;
        }
        h2,
        h3,
        h4 {
            color: $black;
        }
    }
}
